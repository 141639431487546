.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

.dot {
  width: 8px;
  height: 8px;
  background-color: cornflowerblue; /* or any color of your choice */
  border-radius: 50%;
}

.animate-bounce {
  animation: bounce 1s infinite;
}

.animate-bounce200 {
  animation: bounce 1s infinite;
  animation-delay: 0.2s;
  background-color: rgb(131, 170, 244);
}

.animate-bounce400 {
  animation: bounce 1s infinite;
  animation-delay: 0.4s;
  background-color: rgb(179, 202, 245);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
